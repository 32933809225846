import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import get from "lodash/get"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Slider from "react-slick"
import ReactTooltip from "react-tooltip"
import Card from "../components/card"
import Button, { StyledExternalButton } from "../components/button"
import Map from "../components/map"
import Layout from "../components/layout"
import DatePicker from "../components/date-picker"
import { colors } from "../theme"
import { TranslationContext } from "../translate"
import sliderArrowImage from "../images/slider-arrow.png"
import { StyledPageContent } from "../components/page-section"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const HeroVideo = styled('video')`
  display: block;
  width: 100%;
  height: 40em;
  max-height: calc(100vh - 5em);
  object-fit: cover;
`

const HeroTitle = styled.h1`
  color: #fff;
  position: absolute;
  top: 4em;
  font-size: 3em;
  text-align: center;
  max-width: 13em;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  text-shadow: 0 0 0.5em rgba(0,0,0,0.75);
`

const HeroOverlayImage = styled.a`
  position: absolute;
  display: block;
  right: 1em;
  top: calc(55% + 1.5em);
  height: calc(50% - 5em);
  width: auto;
  user-select: none;
  object-fit: contain;
  @media (min-width: 769px) {

  }
  @media (min-width: 1024px) {
  }
  @media (min-width: 1280px) {
  }
  img {
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
`

const HeroOverlayImage2 = styled(HeroOverlayImage)`
  top: 7em;
  right: 1.2em;
  @media (min-width: 769px) {
    right: 1.7em;
  }
`

const HeroWidget = styled.div`
  position: absolute;
  left: 3.5em;
  top: 50%;
  margin-top: -6em;
  display: none;
  @media (min-width: 1024px) {
    display: none;
  }
`

const Intro = styled('div')`
  background: #333230;
  padding: 6em 2em 4em 2em;
  text-align: center;
  position: relative;
  margin-bottom: 2em;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -3em;
    border: 3em solid transparent;
    border-top-color: #333230;
  }
`

const IntroTitle = styled('h2')`
  font-size: 1em;
  margin: 0;
  @media (min-width: 769px) {
    flex-direction: row;
    font-size: 2em;
  }
`

const IntroLine = styled('span')`
  color: #B99874;
  display: block;
  font-weight: 300;
  max-width: 23em;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4em;
`

const IntroLinePrimary = styled(IntroLine)`
  font-weight: 700;
  font-size: 6.4vw;
  line-height: 1em;
  margin-bottom: 0.2em;
  @media (min-width: 769px) {
    flex-direction: row;
    font-size: 2.1em;
  }
`

const IntroIcons = styled('div')`
  margin: 2.5em auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 50em;
  font-size: 0.7em;
  @media (min-width: 769px) {
    font-size: 1em;
  }
`

const IntroIconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.2em;
  opacity: 1;
  transition: opacity 300ms ease-out;
  font-size: 2.4vw;
  @media (min-width: 769px) {
    opacity: 0.25;
    margin: 0.5em;
    font-size: 1em;
  }
  &:hover {
    opacity: 1;
  }
`

const IntroIconImage = styled('img')`
  max-width: 7em;
  height: 5em;
  margin-bottom: 1em;
  background-image: url(${({src}) => src});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`

const IntroIconTitle = styled('div')`
  color: rgb(162 161 154);
  text-transform: uppercase;
  font-weight: 500;
`

const IntroContent = styled('div')`
  color: #73726E;
  font-weight: 500;
  font-style: italic;
  line-height: 1.5em;
  padding: 2em 1em;
  max-width: 50em;
  margin: 0 auto;
  @media (min-width: 769px) {
    padding: 2em;
  }
`

const IntroButtons = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  max-width: 20em;
  & > * {
    margin: 0.25em 0;
  }
  @media (min-width: 769px) {
    flex-direction: row;
    max-width: none;
    & > * {
      margin: 0 0.25em;
      & > *:last-child {
        margin-left: 1em;
      }
    }
  }
`

const IntroFooterImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: -1em;
  flex-wrap: wrap;
`

const IntroFooterImage = styled.img`
  margin: 1em;
  width: 7em;
`

const Section = styled('section')`
  overflow: hidden;
  max-width: 90em;
  margin: 0 auto;
`

const SectionTitle = styled('h2')`
  font-size: 3em;
  text-transform: ${({uppercase}) => uppercase ? 'uppercase' : 'none'};
  font-weight: 800;
  text-align: center;
  padding: 0 0.25em;
  margin: 1em 0 0.5em 0;
  margin-top: max(1em, 6vw);
  line-height: 1em;
  & > strong {
    font-weight: 200;
  }
  @media (min-width: 641px) {
    font-size: 4em;
  }
`

const SectionSubtitle = styled('h3')`
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  color: #AAA9A7;
  margin-top: -0.2em;
`

const SectionContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
  }
`

const SectionHeader = styled('div')`
  background-image: url(${({backgroundImage}) => backgroundImage});
  background-position: left center;
  background-size: cover;
  position: relative;
  padding: 0 1em;
  min-height: 15em;
  @media (min-width: 769px) {
    flex: 0 0 50%;
    padding: 0;
  }
`

const SectionHeaderContent = styled('div')`
  background: rgba(255,255,255,0.80);
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  margin: 3em auto;
  min-height: 15em;
  max-width: 20em;
  display: none;
  @media (min-width: 769px) {
    margin: 3em;
    max-width: none;
    display: block;
  }
  @media (min-width: 1024px) {
    min-height: auto;
    margin: 0;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
  }
`

const SectionHeaderLinks = styled('div')`
  display: flex;
  flex-direction: row;
  letter-spacing: 0.1em;
  align-items: flex-end;
  flex: 1;
`

const SectionHeaderLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #333230;
  &:hover {
    text-decoration: underline;
  }
`

const SectionHeaderSeparator = styled('span')`
  flex: 1;
  text-align: center;
`

const SectionContent = styled('div')`
  font-weight: 500;
  font-style: italic;
  line-height: 1.35em;
  padding: 0 4em 2em 3em;
  box-sizing: border-box;
  max-width: 55em;
  text-align: center;
  & > p:first-child {
    color: ${({color}) => color};
    font-weight: 700;
  }
  a {
    color: ${({color}) => color};
  }
  @media (min-width: 769px) {
    flex: 0 0 ${({fullWidth}) => fullWidth ? "100%" : "50%"};
    text-align: ${({fullWidth}) => fullWidth ? "center" : "left"};
  }
`

const SectionImageWrapper = styled('div')`
  display: block;
  width: 50%;
  position: relative;
  padding-top: 50%;
`

const SectionImage = styled('img')`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`

const ContentBlocks = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -3em 0 4em 0;
  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
`

const ContentBlock = styled('div')`
  white-space: pre-wrap;
  text-align: center;
  width: 16em;
  padding: 1em 2em;
  a {
    color: inherit;
  }
  @media (min-width: 769px) {
    &:not(:last-child) {
      border-right: 2px solid #EAEAE9;
    }
  }
`

const ContentBlockTitle = styled('h3')`
  color: #B99874;
  font-weight: 400;
  font-size: 1.3em;
  text-transform: uppercase;
  margin: 0 0 0.5em 0;
`

const ContentBlockContent = styled('div')`
  line-height: 1.75em;
`

const Certificates = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const Certificate = styled('img')`
  width: auto;
  height: 5em;
  object-fit: contain;
  margin: 1em;
`

const MapWrapper = styled('div')`
  position: relative;
`

const DatePickerWrapper = styled.div`
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 4em;
  background: #ebe7dd;
  box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
  font-size: 1.1em;
`

const DatePickerTitle = styled.h2`
  font-size: 1em;
  margin: 0 0 1em 0;
`

const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledExternalButton} {
    display: flex;
    align-self: flex-start;
    &:hover {
      background: ${colors.pakuri} !important;
    }
  }
`

const Section2Content = styled.div`
  max-width: 60em;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 3em;
  padding: 3em 0 4em 0;
`

const Section2Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Section2Image = styled.img`
  width: 100%;
  margin-bottom: 2em;
  display: block;
`

const Section2Header = styled.h2`
  font-weight: 400;
  font-size: 1.8em;
  margin: 0 0 0.75em 0;
`

const Section2HeaderCentered = styled(Section2Header)`
  text-align: center;
  margin-bottom: -0.5em;
`

const Section2Text = styled.div`
  line-height: 1.5em;
  margin-bottom: 1.5em;
`

const Section2Mid = styled(Section2)`
  background: #f1eae3;
`

const Section2Dark = styled(Section2)`
  background: rgb(51, 50, 48);
  color: #EFEFEE;
  ${Section2Header} {
    color: rgb(185, 152, 116);
  }
`

const Section2ContentBox = styled(Section2Content)`
  background: #fff;
  padding: 0;
  margin-top: 4em;
  &:last-child {
    margin-bottom: 4em;
  }
  gap: 0;
  ${Section2Header} {
  }
  ${Section2Image} {
    margin-bottom: 0;
  }
  ${Section2Column} {
    justify-content: center;
    & > *:not(img) {
      margin-left: 3rem;
      margin-right: 3rem;
    }
  }
`

const SliderWrapper = styled.div`
  width: calc(100% + 1em);
  max-width: 100vw;
  overflow: hidden;
  margin-top: -4em;
  margin-right: -1em;
`


const SliderArrowRight = styled.div`
  font-size: inherit !important;
  width: 2em;
  height: 2em;
  right: -3em;
  transform: translateY(-50%);
  background: transparent url(${sliderArrowImage}) no-repeat center center;
  background-size: contain;
  &:before {
    content: none !important;
    display: none !important;
  }
  &:hover {
    background: transparent url(${sliderArrowImage}) no-repeat center center;
    background-size: contain;
    opacity: 0.5;
  }
  @media (max-width: 980px) {
    display: none !important;
  }
`

const SliderArrowLeft = styled(SliderArrowRight)`
  transform: translateY(-50%) scaleX(-1);
  right: auto;
  left: -3em;
`



const SliderImageWrapper = styled('div')`
  display: block;
  width: 100%;
  position: relative;
  padding-top: 60%;
`

const SliderImage = styled.img`
  object-fit: cover;
  width: calc(100% - 1em);
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`

const ReviewPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`


const ReviewPlaceholderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 1em);
  height: 100%;
  background: #f0f0f0;
  text-align: center;
  color: #a0a0a0;
`


const IndexPage = ({data, location}) => {
  const sections = get(data, 'allContentfulHomeSection.edges')
  const layoutSettings = get(data, 'allContentfulLayoutSettings.edges[0].node')
  const introLine1 = get(layoutSettings, 'introLine1')
  const introLine2 = get(layoutSettings, 'introLine2')
  const introContent = get(layoutSettings, 'introContent.introContent')
  const videoUrlMp4 = get(layoutSettings, 'headerVideo.localFile.publicURL')
  const videoUrlWebm = get(layoutSettings, 'headerVideoWebm.localFile.publicURL')
  const introIcons = get(data, 'allContentfulIntroIcon.edges')
  const introButtons = get(layoutSettings, 'introButtons') || []
  const introFooterImages = get(layoutSettings, 'introFooterImages') || []

  const heroContent = <>
    <HeroVideo playsInline autoPlay muted loop>
      <source src={videoUrlWebm} type="video/webm" />
      <source src={videoUrlMp4} type="video/mp4" />
    </HeroVideo>
    <HeroTitle>Luontohotelli Nuuksiossa, johon pääset 30 minuutissa keskustasta tai lentokentältä</HeroTitle>
    <DatePickerWrapper>
      <DatePickerTitle>Varaa majoitus</DatePickerTitle>
      <DatePicker />
    </DatePickerWrapper>
  </>

  return (
    <Layout data={data} location={location}>

    <TranslationContext.Consumer>
      {(t) => {
      return <>
        <StyledPageContent>
        <h1>Hotellihuoneet</h1>
        <h2>Ekologinen design-hotelli luonnon helmassa</h2>
        <Section2>
          <Section2Content>
            <Section2Column>
              <Section2Image src={require("../images/photos/hotel.jpeg")} />
              <Section2Header>
                Hotellihuoneet
              </Section2Header>
              <Section2Text>
                Hotellihuoneemme ovat rauhoittavan harmonisia ja niissä on kaikki tarpeellinen, mutta ei mitään turhaa. Hotellimme sisustus on tyylikkään
                minimalistinen ja materialit ovat luonnonmukaisia ja kotimaisia.
              </Section2Text>
              <StyledExternalButton>Lue lisää ja varaa huone</StyledExternalButton>
            </Section2Column>
            <Section2Column id="glamping">
              <Section2Image src={require("../images/photos/glamping.jpeg")} />
              <Section2Header>
                Glamping-teltat
              </Section2Header>
              <Section2Text>
                Teltat on varusteltu korkeatasoisilla Unikulman vuoteilla sekä untuvapeitoilla
                ja-tyynyillä. Lodge Glampingeissä yövyt keskellä luonnon aänia, sillä
                puukehikon päälle pingotettu telttakangas pääst luonnon äänet sisälle,
                mutta pitää sateen ja tuulen ulkona.
              </Section2Text>
              <StyledExternalButton>Lue lisää ja varaa glamping</StyledExternalButton>
            </Section2Column>
          </Section2Content>
        </Section2>


        <Section2Mid>
          <Section2Content>
            <Section2Column>
              <Section2Header>
                Teemme kestäviä valintoja
              </Section2Header>
              <Section2Text>
                Kaikki toimintamme rakentuu vakaasti luonnonsuojelun,
                kestävän kehityksen ja paikallisuuden periaatteille.
              </Section2Text>
              <StyledExternalButton>Lue lisää vastuullisuudesta</StyledExternalButton>
            </Section2Column>
            <Section2Column>
            </Section2Column>
          </Section2Content>
        </Section2Mid>



        <Section2>
          <Section2Content>
            {sections.filter(({node}) => node.certificates && node.certificates.length > 0).map(({node}, i) =>
              <Section key={i} id={node.identifier}>
                {node.certificates && node.certificates.length > 0 &&
                  <Certificates>
                    {node.certificates.map((cert, i) => 
                      <Certificate /* data-tip={cert.title} */ key={i} src={cert.localFile.childImageSharp.fixed.src} />)
                    }
                  </Certificates>
                }
              </Section>
            )}
          </Section2Content>
        </Section2>


        <Section2Mid>
          <Section2ContentBox id="kokous">
            <Section2Column>
              <Section2Image src={require("../images/photos/kokous.jpeg")} />
            </Section2Column>
            <Section2Column>
              <Section2Header>
                Järjestä kokous
              </Section2Header>
              <Section2Text>
                Luova irtiotto, strategiapäivät tai johtoryhmä tai
                tilauksessa? Meillä on upeat kokoustilat, puhdas luonto ja aitoja elämyksiä. NOOX-kokoustila soveltuu
                parhaiten noin 15-20 henkilön kokouksiin.
                Pienempiin kokouksiin soveltuu myös saunamme
                Vilpola.
              </Section2Text>
              <StyledExternalButton>Pyydä tarjous</StyledExternalButton>
            </Section2Column>
          </Section2ContentBox>

          <Section2ContentBox id="bistro">
            <Section2Column>
              <Section2Header>
                Bistro
              </Section2Header>
              <Section2Text>
                Forest Loungessa on tarjolla erä-eleganssin lisäksi kylmia ja kuumia juomia, suolaisia ja makeita
                hiukopaloja, retki-snacksejä, laatuviinejä sekä Kyrö
                Distilleryn drinkkejä.
              </Section2Text>
              <StyledExternalButton>Lue lisää</StyledExternalButton>
            </Section2Column>
            <Section2Column>
              <Section2Image src={require("../images/photos/bistro.jpeg")} />
            </Section2Column>
          </Section2ContentBox>


          <Section2ContentBox id="sauna">
            <Section2Column>
              <Section2Image src={require("../images/photos/sauna.jpeg")} />
            </Section2Column>
            <Section2Column>
              <Section2Header>
                Sauna
              </Section2Header>
              <Section2Text>
                Hotelli- ja glamping-majoitukseen kuuluu aina ilmainen aamusauna, joka on klo xx-xx. Saunominen on mahdollista myös iltaisin, mutta sitä varten täytyy
                tehdä varaus, sillä tilaa on vain 8 saunojalle. Sauna on varattavissa vain Lake Lodgen majoittujille.
              </Section2Text>
              <StyledExternalButton>Varaa iltasauna</StyledExternalButton>
            </Section2Column>
          </Section2ContentBox>
        </Section2Mid>




        <Section2>
          <Section2Content>
            <Section2Column id="elamykset">
              <Section2Header>
                Elämykset
              </Section2Header>
              <Section2Text>
                Majoituksen lisäksi järjestämme täydellisiä luontoelämyksiä, jotta
                vierailusi olisi inspiroiva ja virkistävä. Selaa mieleisesi elämyksiä ja
                ota meihin yhteyttä niin järjestämme sinulle haluamasi elämykset.
              </Section2Text>
            </Section2Column>
            <Section2Column>
              <input type="text" style={{padding: "0.5em", marginBottom: "0.5em"}} />
              <textarea style={{padding: "0.5em", marginBottom: "0.5em"}}></textarea>
              <StyledExternalButton>Ota yhteyttä</StyledExternalButton>
            </Section2Column>
          </Section2Content>
        </Section2>

        <Section2>
          <Section2Content>
            <SliderWrapper>
              <Slider 
                slidesToShow={4} 
                slidesToScroll={1} 
                infinite 
                autoplay 
                speed={750} 
                autoplaySpeed={5000} 
                pauseOnHover={false}
                arrows={true}
                nextArrow={<SliderArrowRight src={sliderArrowImage} />}
                prevArrow={<SliderArrowLeft src={sliderArrowImage} />}
              >
                <SliderImageWrapper><SliderImage src={require("../images/photos/hotel.jpeg")} /></SliderImageWrapper>
                <SliderImageWrapper><SliderImage src={require("../images/photos/glamping.jpeg")} /></SliderImageWrapper>
                <SliderImageWrapper><SliderImage src={require("../images/photos/kokous.jpeg")} /></SliderImageWrapper>
                <SliderImageWrapper><SliderImage src={require("../images/photos/bistro.jpeg")} /></SliderImageWrapper>
                <SliderImageWrapper><SliderImage src={require("../images/photos/sauna.jpeg")} /></SliderImageWrapper>
              </Slider>
            </SliderWrapper>
          </Section2Content>
        </Section2>


        <Section2Mid>
          <Section2Content>
            <Section2Column>
              <Section2Header>
                Tietoa alueesta
              </Section2Header>
              <Section2Text>
                53 km2 metsää<br/>
                8 merkittyä vaellusreittiä<br/>
                30+ km pyöräilyreittejä<br/>
                20+ km ratsastureittejä<br/>
                Kymmeniä järviä ja lampia<br/>
                Leiri- ja nuotiopaikkoja<br/>
              </Section2Text>
            </Section2Column>
            <Section2Column>
            </Section2Column>
          </Section2Content>
        </Section2Mid>

        <Section2>
          <Section2Content>
            <Section2Column>
              <Section2HeaderCentered>Mitä asiakkaamme sanovat</Section2HeaderCentered>
            </Section2Column>
          </Section2Content>
          <Section2Content>
            <SliderWrapper>
              <Slider 
                slidesToShow={4} 
                slidesToScroll={1} 
                infinite 
                autoplay 
                speed={750} 
                autoplaySpeed={5000} 
                pauseOnHover={false}
                arrows={true}
                nextArrow={<SliderArrowRight src={sliderArrowImage} />}
                prevArrow={<SliderArrowLeft src={sliderArrowImage} />}
              >
                <ReviewPlaceholder><ReviewPlaceholderContent>TripAdvisor-kortti tähän</ReviewPlaceholderContent></ReviewPlaceholder>
                <ReviewPlaceholder><ReviewPlaceholderContent>TripAdvisor-kortti tähän</ReviewPlaceholderContent></ReviewPlaceholder>
                <ReviewPlaceholder><ReviewPlaceholderContent>TripAdvisor-kortti tähän</ReviewPlaceholderContent></ReviewPlaceholder>
                <ReviewPlaceholder><ReviewPlaceholderContent>TripAdvisor-kortti tähän</ReviewPlaceholderContent></ReviewPlaceholder>
                <ReviewPlaceholder><ReviewPlaceholderContent>TripAdvisor-kortti tähän</ReviewPlaceholderContent></ReviewPlaceholder>
              </Slider>
            </SliderWrapper>
          </Section2Content>
        </Section2>


        <MapWrapper>
          <Map />
        </MapWrapper>
        <ReactTooltip place="top" effect="solid" type="dark" />
        </StyledPageContent>
        </>
      }}
      </TranslationContext.Consumer>
    </Layout>
  )
}

export default injectIntl(IndexPage);
export const pageQuery = graphql`
  query IndexQuery2($locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulContentBlock(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          content {
            raw
          }
        }
      }
    }
    allContentfulHomeSection(filter: { node_locale: { eq: $locale } } sort: { fields: [order], order: ASC }) {
      edges {
        node {
          identifier
          title
          subtitle
          content {
            raw
            references {
              ... on ContentfulContentBlock {
                title
                content {
                  raw
                }
              }
            }
          }
          order
          primaryNavigation
          primaryImage {
            localFile {
              childImageSharp {
                fixed(width: 1000) {
                  src
                }
              }
            }
          }
          images {
            localFile {
              childImageSharp {
                fixed(width: 1000) {
                  src
                }
              }
            }
          }
          color
          certificates {
            title
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  src
                }
              }
            }
          }
          cards {
            ...Card
          }
          buttons {
            ...Button
          }
          linkButtons {
            ...Button
          }
        }
      }
    }
    allContentfulIntroIcon(filter: { node_locale: { eq: $locale } } sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          image {
            localFile {
              url
            }
          }
        }
      }
    }
    allContentfulLayoutSettings(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
          introLine1
          introLine2
          introContent {
            introContent
          }
          introButtons {
            ...Button
          }
          primaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          secondaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          tertiaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          headerVideo {
            localFile {
              publicURL
            }
          }
          headerVideoWebm {
            localFile {
              publicURL
            }
          }
          headerOverlayImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          headerOverlayImageLink
          headerOverlayImage2 {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          headerOverlayImageLink2
          introFooterImages {
            localFile {
              childImageSharp {
                fixed(width: 500) {
                  src
                }
              }
            }
          }
          cookieConsentText
          cookieConsentButtonText
          sideMenuButtons {
            ...Button
          }
          primaryMenuButtons {
            ...Button
          }
          secondaryMenuButtons {
            ...Button
          }
          footerMenuButtons {
            ...Button
          }
          ogTitle
          ogDescription
          ogImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          serviceNotification
          notificationBannerContent {
            raw
          }
          showTripAdvisorWidget
        }
      }
    }
    allContentfulContactInformation(filter: {node_locale: {eq: $locale}} sort: { fields: [order], order: ASC }) {
      edges {
        node {
          icon {
            localFile {
              publicURL
            }
          }
          order
          linkUrl
          label
        }
      }
    }
    allContentfulSocialMediaLink(filter: {node_locale: {eq: $locale}} sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
